import React, { Fragment, useState, useContext, useEffect } from "react";
import { Dropdown, Button, Modal, Alert, Table } from "react-bootstrap";
import profile from "../../images/profile/profile.png";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import Donut2 from "../components/Mophy/MyWallet/Donut2";
import PerfectScrollbar from "react-perfect-scrollbar";

import PostDetails from "../components/AppsMenu/AppProfile/PostDetails";
import { set } from "lodash";

const PartnerDashboard = () => {
  const [sendMessage, setSendMessage] = useState(false);
  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );
  const user = useSelector((state) => state.user.user);
  const [reflink, setreflink] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showTable, setShowTable] = useState(false);

  const ranks = [
    { name: "Bronze", turnover: 0 },
    { name: "Silver", turnover: 500 },
    { name: "Gold", turnover: 1000 },
    // ... add more ranks as needed ...
    { name: "Diamond", turnover: 5000 },
  ];

  useEffect(() => {
    setreflink(
      `${window.location.origin}/page-register?referral=${localUser.username}`
    );
  }, [localUser]);

  const calculateUserRank = (turnover) => {
    let currentRank = ranks[0];
    let nextRank = null;
    let turnoverLeft = 0;

    for (let i = 0; i < ranks.length; i++) {
      if (turnover >= ranks[i].turnover) {
        currentRank = ranks[i];
        nextRank = ranks[i + 1] || null;
      } else {
        break;
      }
    }

    if (nextRank) {
      turnoverLeft = nextRank.turnover - turnover;
    }

    const progress = nextRank
      ? Math.min(
          ((turnover - currentRank.turnover) /
            (nextRank.turnover - currentRank.turnover)) *
            100,
          100
        )
      : 100;

    return { currentRank, nextRank, progress, turnoverLeft };
  };
  const { currentRank, nextRank, progress, turnoverLeft } = calculateUserRank(
    750 || 0
  );

  const handleCopyClick = () => {
    navigator.clipboard.writeText(reflink).then(() => {
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 3000); // Hide the alert after 3 seconds
    });
  };

  const handleShowTable = () => {
    setShowTable(true);
  };

  return (
    <Fragment>
      <div className="form-head mb-4">
        <h2 className="text-black font-w600 mb-0">Partner Dashboard</h2>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="profile card card-body px-3 pt-3 pb-0">
            <div className="profile-head">
              <div className="photo-content ">
                <div
                  className="cover-photo rounded"
                  style={{
                    fontSize: "2rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "red",
                  }}
                >
                  VIELLEICHT IRGENDEIN COOLES BILD HIER ?
                </div>
              </div>
              <div className="profile-info">
                <div
                  className="profile-details"
                  style={{ alignItems: "center" }}
                >
                  <div className="profile-name px-3 pt-2">
                    <h4 className="text-primary mb-0">Sponsor</h4>
                    <p>{localUser.referredBy}</p>
                  </div>
                  <div className="profile-email px-2 pt-2">
                    <h4 className="text-light mb-0">Ref Link</h4>
                    <p>{reflink}</p>
                  </div>

                  <Button
                    variant="primary"
                    className="ms-auto"
                    onClick={handleCopyClick}
                  >
                    <i className="fa fa-copy" aria-hidden="true"></i> Copy
                  </Button>
                </div>
              </div>
              {showAlert && (
                <div className="row">
                  <div className="col-lg-12">
                    <Alert variant="success" className="mt-3">
                      Referral link copied to clipboard!
                    </Alert>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="card stacked-2">
            <div className="card-header flex-wrap border-0 pb-0 align-items-end">
              <div className="d-flex align-items-center mb-3 me-3 w-100">
                <svg
                  className="me-3"
                  width="68"
                  height="68"
                  viewBox="0 0 68 68"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M59.4999 31.688V19.8333C59.4999 19.0818 59.2014 18.3612 58.6701 17.8298C58.1387 17.2985 57.418 17 56.6666 17H11.3333C10.5818 17 9.86114 16.7014 9.32978 16.1701C8.79843 15.6387 8.49992 14.9181 8.49992 14.1666C8.49992 13.4152 8.79843 12.6945 9.32978 12.1632C9.86114 11.6318 10.5818 11.3333 11.3333 11.3333H56.6666C57.418 11.3333 58.1387 11.0348 58.6701 10.5034C59.2014 9.97208 59.4999 9.25141 59.4999 8.49996C59.4999 7.74851 59.2014 7.02784 58.6701 6.49649C58.1387 5.96514 57.418 5.66663 56.6666 5.66663H11.3333C9.07891 5.66663 6.9169 6.56216 5.32284 8.15622C3.72878 9.75028 2.83325 11.9123 2.83325 14.1666V53.8333C2.83325 56.0876 3.72878 58.2496 5.32284 59.8437C6.9169 61.4378 9.07891 62.3333 11.3333 62.3333H56.6666C57.418 62.3333 58.1387 62.0348 58.6701 61.5034C59.2014 60.9721 59.4999 60.2514 59.4999 59.5V47.6453C61.1561 47.0683 62.5917 45.9902 63.6076 44.5605C64.6235 43.1308 65.1693 41.4205 65.1693 39.6666C65.1693 37.9128 64.6235 36.2024 63.6076 34.7727C62.5917 33.3431 61.1561 32.265 59.4999 31.688ZM53.8333 56.6666H11.3333C10.5818 56.6666 9.86114 56.3681 9.32978 55.8368C8.79843 55.3054 8.49992 54.5847 8.49992 53.8333V22.1453C9.40731 22.4809 10.3658 22.6572 11.3333 22.6666H53.8333V31.1666H45.3333C43.0789 31.1666 40.9169 32.0622 39.3228 33.6562C37.7288 35.2503 36.8333 37.4123 36.8333 39.6666C36.8333 41.921 37.7288 44.083 39.3228 45.677C40.9169 47.2711 43.0789 48.1666 45.3333 48.1666H53.8333V56.6666ZM56.6666 42.5H45.3333C44.5818 42.5 43.8611 42.2015 43.3298 41.6701C42.7984 41.1387 42.4999 40.4181 42.4999 39.6666C42.4999 38.9152 42.7984 38.1945 43.3298 37.6632C43.8611 37.1318 44.5818 36.8333 45.3333 36.8333H56.6666C57.418 36.8333 58.1387 37.1318 58.6701 37.6632C59.2014 38.1945 59.4999 38.9152 59.4999 39.6666C59.4999 40.4181 59.2014 41.1387 58.6701 41.6701C58.1387 42.2015 57.418 42.5 56.6666 42.5Z"
                    fill="#1EAAE7"
                  />
                </svg>
                <div className="me-auto">
                  <h5 className="fs-20 text-black font-w600">
                    Total Partner Balance
                  </h5>
                  <span className="text-num text-black font-w600">
                    ${localUser.partner_balance}
                  </span>
                </div>

                <Button
                  variant="primary"
                  className="ms-auto"
                  onClick={handleCopyClick}
                >
                  {/* <i className="fa fa-wallet" aria-hidden="true"></i>  */}
                  Withdraw
                </Button>
              </div>
            </div>
            <div className="card-body">
              <div className="progress mb-4" style={{ height: "24px" }}>
                <div
                  className="progress-bar bg-inverse progress-animated"
                  style={{ width: `${progress}%`, height: "24px" }}
                  role="progressbar"
                >
                  <span className="sr-only">100% Complete</span>
                </div>
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    textAlign: "center",
                    lineHeight: "24px",
                    color: "white",
                    fontSize: "1rem",
                  }}
                >
                  {nextRank
                    ? `User Rank: ${currentRank.name} (${progress.toFixed(
                        0
                      )}% to ${nextRank.name}, $${turnoverLeft} left)`
                    : `User Rank: ${currentRank.name} (Max Rank Achieved)`}
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-xl-3 mb-3 col-xxl-6 col-sm-6">
                  <div className="media align-items-center bgl-secondary rounded p-2">
                    <span className="bg-white rounded-circle p-3 me-4">
                      <i className="fa fa-users fa-xl	" aria-hidden="true"></i>
                    </span>
                    <div className="d-inline-block me-3 position-relative donut-chart-sale2"></div>
                    <div className="media-body">
                      <h4 className="fs-15 text-black font-w600 mb-0">
                        Total Team Members
                      </h4>
                      <span className="fs-14">0</span>
                    </div>{" "}
                    <Button
                      variant="primary"
                      className="ms-auto"
                      onClick={handleShowTable}
                    >
                      {/* <i className="fa fa-wallet" aria-hidden="true"></i>  */}
                      Show
                    </Button>
                  </div>
                </div>
                <div className="col-xl-3 mb-3 col-xxl-6 col-sm-6">
                  <div className="media bgl-success rounded p-2 align-items-center">
                    <span className="bg-white rounded-circle p-3 me-4">
                      <i className="fa fa-gem fa-xl	" aria-hidden="true"></i>
                    </span>
                    <div className="media-body">
                      <h4 className="fs-15 text-black font-w600 mb-0">
                        Total Ref Commission
                      </h4>
                      <span className="fs-14">$3,784</span>
                    </div>
                  </div>
                </div>
              </div>
              {showTable && (
                <Table responsive className="header-border ">
                  <thead>
                    <tr>
                      <th>Level</th>
                      <th>Team Members</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td> <td>0</td>
                    </tr>
                    <tr>
                      <td>2</td> <td>0</td>
                    </tr>
                    <tr>
                      <td>3</td> <td>0</td>
                    </tr>
                    <tr>
                      <td>4</td> <td>0</td>
                    </tr>
                    <tr>
                      <td>5</td> <td>0</td>
                    </tr>
                    <tr>
                      <td>6</td> <td>0</td>
                    </tr>
                    <tr>
                      <td>7</td> <td>0</td>
                    </tr>
                    <tr>
                      <td>8</td> <td>0</td>
                    </tr>
                    <tr>
                      <td>9</td> <td>0</td>
                    </tr>
                    <tr>
                      <td>10</td> <td>0</td>
                    </tr>
                    <tr>
                      <td>11</td> <td>0</td>
                    </tr>
                    <tr>
                      <td>12</td> <td>0</td>
                    </tr>
                    <tr>
                      <td>13</td> <td>0</td>
                    </tr>
                    <tr>
                      <td>14</td> <td>0</td>
                    </tr>
                    <tr>
                      <td>15</td> <td>0</td>
                    </tr>
                  </tbody>
                </Table>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-4 col-xxl-12 col-lg-12">
          <div className="card">
            <div className="card-header border-0 pb-0">
              <h4 className="card-title">Notifications</h4>
            </div>
            <div className="card-body">
              <Table responsive className="header-border ">
                {/* <thead>
                    <tr>
                      <th></th>
                      <th>Level</th>
                      <th>Team Members</th>
                    </tr>
                  </thead> */}
                <tbody>
                  <tr>
                    <td>
                      {" "}
                      <div className="widget-media ">
                        <div className=" timeline ">
                          <div className=" timeline-panel border-0 m-0">
                            <div className="media me-2">
                              <div className="media me-2 media-info">KG</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>{" "}
                    <td>$50 Ref Commission from User XYZ </td>{" "}
                    <td>29 July 2023 - 02:26 PM</td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <div className="widget-media ">
                        <div className=" timeline ">
                          <div className=" timeline-panel border-0 m-0">
                            <div className="media me-2">
                              <div className="media me-2 media-info">KG</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>{" "}
                    <td>User XYZ signed up as your partner</td>{" "}
                    <td>29 July 2023 - 02:26 PM</td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <div className="widget-media ">
                        <div className=" timeline ">
                          <div className=" timeline-panel border-0 m-0">
                            <div className="media me-2">
                              <div className="media me-2 media-info">KG</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>{" "}
                    <td>$50 Ref Commission from User XYZ </td>{" "}
                    <td>29 July 2023 - 02:26 PM</td>
                  </tr>
                </tbody>
              </Table>
              {/* <PerfectScrollbar
                  style={{ height: "370px" }}
                  id="DZ_W_Todo1"
                  className="widget-media dz-scroll ps ps--active-y"
                >
                  <ul className="timeline">
                    <li>
                      <div className="timeline-panel">
                        <div className="media me-2">
                          <div className="media me-2 media-info">KG</div>
                        </div>
                        <div className="media-body">
                          <h5 className="mb-1">Dr sultads Send you Photo</h5>
                          <small className="d-block">
                            29 July 2023 - 02:26 PM
                          </small>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="timeline-panel">
                        <div className="media me-2 media-info">KG</div>
                        <div className="media-body">
                          <h5 className="mb-1">Resport created successfully</h5>
                          <small className="d-block">
                            29 July 2023 - 02:26 PM
                          </small>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="timeline-panel">
                        <div className="media me-2 media-success">
                          <i className="fa fa-home"></i>
                        </div>
                        <div className="media-body">
                          <h5 className="mb-1">Reminder : Treatment Time!</h5>
                          <small className="d-block">
                            29 July 2023 - 02:26 PM
                          </small>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="timeline-panel">
                        <div className="media me-2">
                          <div className="media me-2 media-info">KG</div>
                        </div>
                        <div className="media-body">
                          <h5 className="mb-1">Dr sultads Send you Photo</h5>
                          <small className="d-block">
                            29 July 2023 - 02:26 PM
                          </small>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="timeline-panel">
                        <div className="media me-2 media-danger">KG</div>
                        <div className="media-body">
                          <h5 className="mb-1">Resport created successfully</h5>
                          <small className="d-block">
                            29 July 2023 - 02:26 PM
                          </small>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="timeline-panel">
                        <div className="media me-2 media-primary">
                          <i className="fa fa-home"></i>
                        </div>
                        <div className="media-body">
                          <h5 className="mb-1">Reminder : Treatment Time!</h5>
                          <small className="d-block">
                            29 July 2023 - 02:26 PM
                          </small>
                        </div>
                      </div>
                    </li>
                  </ul>
                </PerfectScrollbar> */}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PartnerDashboard;

import React, { useState } from "react";
import axios from "axios";

const StepTwo = ({ cardHolderId, onSubmitSuccess }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    cardHolderID: cardHolderId,
    idNumber: "",
    idIssuedBy: "",
    idExpDate: "",
    idIssueDate: "",
    idType: "",
    idDocLang: 0,
    id_FrontSide: "",
    id_BackSide: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      setFormData({ ...formData, [e.target.name]: reader.result });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const apiEndpoint =
      "https://dashboard.croesus-asset.management/api/physical-cards/holder-id-document";
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization:
        "Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg",
    };

    try {
      const response = await axios.post(apiEndpoint, formData, { headers });
      console.log(response.data); // Handle the response as needed
      onSubmitSuccess(true); // Call this when submission is successful
      setIsLoading(false);
    } catch (error) {
      console.error("There was an error!", error);
      setIsLoading(false);
    }
  };

  return (
    <section>
      <form onSubmit={handleSubmit}>
        <div className="row">
          {/* ID Document Fields */}
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label">Select Document*</label>
              <select
                name="idType"
                className="form-control"
                onChange={handleChange}
                value={formData.idType}
              >
                <option value="0">Passport</option>
                <option value="1">Driver License</option>
                <option value="2">National Id</option>
              </select>
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label"> Document Language*</label>
              <select
                name="idDocLang"
                className="form-control"
                onChange={handleChange}
                value={formData.idDocLang}
              >
                <option value="0">English</option>
                <option value="1">Spanish</option>
                <option value="2">French</option>
                <option value="3">Arabic</option>
                <option value="4">Chinese</option>
                <option value="5">Japanese</option>
                <option value="6">Russian</option>
                <option value="7">Portuguese</option>
                <option value="8">German</option>
                <option value="9">Italian</option>
                <option value="10">Hindi</option>
                <option value="11">Other</option>
              </select>
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label">Document Number*</label>
              <input
                type="text"
                name="idNumber"
                className="form-control"
                onChange={handleChange}
                value={formData.idNumber}
                required
              />
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label">
                Which Country issued your Document?*
              </label>
              <input
                type="text"
                name="idIssuedBy"
                className="form-control"
                onChange={handleChange}
                value={formData.idIssuedBy}
                required
              />
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label">Date Of Expiry*</label>
              <input
                type="date"
                name="idExpDate"
                className="form-control"
                onChange={handleChange}
                value={formData.idExpDate}
                required
              />
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label">Date Of Issue*</label>
              <input
                type="date"
                name="idIssueDate"
                className="form-control"
                onChange={handleChange}
                value={formData.idIssueDate}
                required
              />
            </div>
          </div>
          <div className="col-12 mb-2">
            <div
              className="text-center"
              style={{ color: "red", fontSize: "1.125rem" }}
            >
              IMPORTANT: <br></br>- Do not blur out any details <br></br>- If
              the document has one page only, please upload the same file to ID
              Front and ID Back Make sure that all the entered details match
              your document!
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label">Document Front Side*</label>
              <input
                type="file"
                name="id_FrontSide"
                className="form-control"
                onChange={handleFileChange}
                required
              />
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label">Document Back Side*</label>
              <input
                type="file"
                name="id_BackSide"
                className="form-control"
                onChange={handleFileChange}
                required
              />
            </div>
          </div>
          <div className="col-lg-12">
            <button
              type="submit"
              className="btn btn-primary "
              style={{ display: "flex", alignItems: "center" }}
            >
              {isLoading ? (
                <>
                  <span
                    className="spinner-border text-secondary"
                    role="status"
                  ></span>
                  <div style={{ marginLeft: "10px" }}>Loading</div>
                </>
              ) : (
                <div>Submit</div>
              )}
            </button>
          </div>
        </div>
      </form>
    </section>
  );
};

export default StepTwo;

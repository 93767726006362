import React, { Fragment, useState, useEffect } from "react";
//import Multistep from "react-multistep";
import { Stepper, Step } from "react-form-stepper";

import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import PageTitle from "../../../layouts/PageTitle";
import axios from "axios";

const Wizard = () => {
  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );
  const [goSteps, setGoSteps] = useState(0);
  const [isStepOneComplete, setIsStepOneComplete] = useState(false);
  const [isStepTwoComplete, setIsStepTwoComplete] = useState(false);
  const [isStepThreeComplete, setIsStepThreeComplete] = useState(false);

  const [cardHolderId, setCardHolderId] = useState("");

  const handleStepThreeSuccess = (success) => {
    setIsStepThreeComplete(success);
  };

  const handleStepTwoSuccess = (success) => {
    setIsStepTwoComplete(success);
  };

  const handleStepOneSuccess = (success) => {
    setIsStepOneComplete(success);
  };

  const handleCardHolderIdReceived = (id) => {
    setCardHolderId(id);
  };

  useEffect(() => {
    if (localUser && localUser.cardHolderId && goSteps === 0) {
      setCardHolderId(localUser.cardHolderId);
      setGoSteps(1);
    }

    const loadData = async () => {
      console.log("check kyc cardholderID: " + localUser.cardHolderId);
      try {
        const response = await axios.post(
          "https://dashboard.croesus-asset.management/api/physical-cards/kyc/status",
          {
            card_holder_id: localUser.cardHolderId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization:
                "Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg",
            },
          }
        );

        // handle your response here
        console.log("loadData", response.data);
      } catch (err) {
        // handle error here
        console.error(err);
      }
    };

    if (localUser.cardHolderId) {
      loadData();
    }
  }, []);

  useEffect(() => {
    if (isStepOneComplete || isStepTwoComplete || isStepThreeComplete) {
      setGoSteps((prevStep) => prevStep + 1);
    }
  }, [isStepOneComplete, isStepTwoComplete, isStepThreeComplete]);

  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="card">
            <div className="card-body">
              <div className="form-wizard ">
                <Stepper className="nav-wizard" activeStep={goSteps}>
                  <Step className="nav-link" onClick={() => setGoSteps(0)} />
                  <Step className="nav-link" onClick={() => setGoSteps(1)} />
                  <Step className="nav-link" onClick={() => setGoSteps(2)} />
                  <Step className="nav-link" onClick={() => setGoSteps(3)} />
                </Stepper>
                {goSteps === 0 && (
                  <>
                    <div
                      className="text-center"
                      style={{ color: "red", fontSize: "1.125rem" }}
                    >
                      To avoid delaying the KYC process, please ensure that you
                      do not make any typing errors.
                    </div>
                    <br></br>
                    <StepOne
                      onSubmitSuccess={handleStepOneSuccess}
                      onCardHolderIdReceived={handleCardHolderIdReceived}
                    />
                    {/* <div className="text-end toolbar toolbar-bottom p-2">
                      <button
                        className="btn btn-primary sw-btn-next"
                        onClick={() => setGoSteps(1)}
                        // disabled={!isStepOneComplete} // Disable button based on state
                      >
                        Next
                      </button>
                    </div> */}
                  </>
                )}
                {goSteps === 1 && (
                  <>
                    <div
                      className="text-center"
                      style={{ color: "red", fontSize: "1.125rem" }}
                    >
                      To avoid delaying the KYC process, please ensure that you
                      do not make any typing errors.
                    </div>
                    <br></br>
                    <StepTwo
                      onSubmitSuccess={handleStepTwoSuccess}
                      cardHolderId={cardHolderId}
                    />
                    {/* <div className="text-end toolbar toolbar-bottom p-2">
                      <button
                        className="btn btn-secondary sw-btn-prev me-1"
                        onClick={() => setGoSteps(0)}
                      >
                        Prev
                      </button>
                      <button
                        className="btn btn-primary sw-btn-next ms-1"
                        onClick={() => setGoSteps(2)}
                        // disabled={!isStepTwoComplete}
                      >
                        Next
                      </button>
                    </div> */}
                  </>
                )}
                {goSteps === 2 && (
                  <>
                    <div
                      className="text-center"
                      style={{ color: "red", fontSize: "1.125rem" }}
                    >
                      To avoid delaying the KYC process, please ensure that you
                      do not make any typing errors.
                    </div>
                    <br></br>
                    <StepThree
                      onSubmitSuccess={handleStepThreeSuccess}
                      cardHolderId={cardHolderId}
                    />
                    {/* <div className="text-end toolbar toolbar-bottom p-2">
                      <button
                        className="btn btn-secondary sw-btn-prev me-1"
                        onClick={() => setGoSteps(1)}
                      >
                        Prev
                      </button>
                      <button
                        className="btn btn-primary sw-btn-next ms-1"
                        onClick={() => setGoSteps(3)}
                        // disabled={!isStepThreeComplete}
                      >
                        Next
                      </button>
                    </div> */}
                  </>
                )}
                {goSteps === 3 && (
                  <>
                    <StepFour />{" "}
                    <div
                      className="col-12 mb-2"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <h5 style={{ color: "red" }}>
                        This process can take up to 72 hours.
                      </h5>
                    </div>
                    <div
                      className="text-end toolbar toolbar-bottom p-2"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      {/* <button
                        className="btn btn-secondary sw-btn-prev me-1"
                        onClick={() => setGoSteps(2)}
                      >
                        Prev
                      </button> */}
                      {/* <button className="btn btn-primary sw-btn-next ms-1">
                        Submit
                      </button> */}

                      <button className="btn btn-primary  ms-1">
                        Check KYC
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Wizard;

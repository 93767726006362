import React from "react";

const StepFour = () => {
  const steps = [
    { name: "User Data", isComplete: true },
    { name: "ID Verification", isComplete: true },
    { name: "Address Verification", isComplete: true },
  ];

  return (
    <section>
      <div className="row">
        <div className="col-lg-12 mb-2">
          <div className="form-group mb-3">
            <h3 className="text-center">All Steps Completed - Pending</h3>
            <ul className="list-group">
              {steps.map((step, index) => (
                <li
                  key={index}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  {step.name}
                  {step.isComplete && (
                    <span className="badge bg-warning">?</span>
                    //   ✓
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StepFour;

import React, { useState } from "react";
import axios from "axios";

const StepOne = ({ onSubmitSuccess, onCardHolderIdReceived }) => {
  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );

  const [isLoading, setIsLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    address_line1: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    phone: "",
    email: "",
    //  cellPhoneNumber: "123456789",
    //  callingCode: "123",
    //  countryCallingCode: "NA",
    birth_date: "",
    genderId: 0,
  });

  const countries = [
    { name: "Afghanistan", isoCode: "AF" },
    { name: "Austria", isoCode: "AT" },
    { name: "Germany", isoCode: "DE" },
  ];

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const apiEndpoint =
      "https://dashboard.croesus-asset.management/api/physical-cards/create-card-holder";
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization:
        "Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg",
    };

    try {
      const fullName = `${formData.firstName} ${formData.lastName}`;
      const dataToSend = {
        name: fullName,
        cellPhoneNumber: formData.phone,
        callingCode: "123",
        countryCallingCode: formData.country,
        ...formData,
      };
      const response = await axios.post(apiEndpoint, dataToSend, { headers });
      console.log(response.data); // Handle the response as needed
      onSubmitSuccess(true); // Call this when submission is successful
      const cardHolderId = response.data.message; // Assuming this is how the ID is received

      const data = {
        userId: localUser.id,
        card_holder_id: cardHolderId,
      };

      fetch("https://api.bridge-bit.com/api/update_user", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then((response) => response.json());

      onCardHolderIdReceived(cardHolderId); // Pass the ID back to the parent component
      setIsLoading(false);
    } catch (error) {
      console.error("There was an error!", error);
      setIsLoading(false);
    }
  };

  return (
    <section>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label">First Name*</label>
              <input
                type="text"
                name="firstName"
                className="form-control"
                onChange={handleChange}
                value={formData.firstName}
                required
              />
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label">Last Name*</label>
              <input
                type="text"
                name="lastName"
                className="form-control"
                onChange={handleChange}
                value={formData.lastName}
                required
              />
            </div>
          </div>
          {/* Additional fields as per API requirements */}
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label">Address Line 1*</label>
              <input
                type="text"
                name="address_line1"
                className="form-control"
                onChange={handleChange}
                value={formData.address_line1}
                required
              />
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label">City*</label>
              <input
                type="text"
                name="city"
                className="form-control"
                onChange={handleChange}
                value={formData.city}
                required
              />
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label">State*</label>
              <input
                type="text"
                name="state"
                className="form-control"
                onChange={handleChange}
                value={formData.state}
                required
              />
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label">Country*</label>
              <select
                name="country"
                value={selectedCountry}
                onChange={handleCountryChange}
                className="form-control" // added the form-control class here
              >
                {countries.map((country, index) => (
                  <option key={index} value={country.isoCode}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label">Postal Code*</label>
              <input
                type="text"
                name="zip"
                className="form-control"
                onChange={handleChange}
                value={formData.zip}
                required
              />
            </div>
          </div>

          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label">Email*</label>
              <input
                type="email"
                name="email"
                className="form-control"
                onChange={handleChange}
                value={formData.email}
                required
              />
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label">Phone Number*</label>
              <input
                type="text"
                name="phone"
                className="form-control"
                onChange={handleChange}
                value={formData.phone}
                required
              />
            </div>
          </div>
          {/* <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label">Cell Phone Number*</label>
              <input
                type="text"
                name="cellPhoneNumber"
                className="form-control"
                onChange={handleChange}
                value={formData.cellPhoneNumber}
                required
              />
            </div>
          </div> */}
          {/* <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label">Calling Code*</label>
              <input
                type="text"
                name="callingCode"
                className="form-control"
                onChange={handleChange}
                value={formData.callingCode}
                required
              />
            </div>
          </div> */}
          {/* <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label">Country Calling Code*</label>
              <input
                type="text"
                name="countryCallingCode"
                className="form-control"
                onChange={handleChange}
                value={formData.countryCallingCode}
                required
              />
            </div>
          </div> */}
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label">Birth Date*</label>
              <input
                type="date"
                name="birth_date"
                className="form-control"
                onChange={handleChange}
                value={formData.birth_date}
                required
              />
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label">Gender*</label>
              <select
                name="genderId"
                className="form-control"
                onChange={handleChange}
                value={formData.genderId}
              >
                <option value="0">Male</option>
                <option value="1">Female</option>
              </select>
            </div>
          </div>
          <div
            className="col-lg-12 "
            style={{ display: "flex", justifyContent: "center" }}
          >
            <button
              type="submit"
              className="btn btn-primary "
              style={{ display: "flex", alignItems: "center" }}
            >
              {isLoading ? (
                <>
                  <span
                    className="spinner-border text-secondary"
                    role="status"
                  ></span>
                  <div style={{ marginLeft: "10px" }}>Loading</div>
                </>
              ) : (
                <div>Submit</div>
              )}
            </button>
          </div>
        </div>
      </form>
    </section>
  );
};

export default StepOne;

import React, { useState } from "react";
import axios from "axios";

const StepThree = ({ cardHolderId, onSubmitSuccess }) => {
  console.log("cardholderID", cardHolderId);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    cardHolderID: cardHolderId,
    address_TypeId: "",
    address_IssuedBy: "",
    address_IssuedDate: "",
    address_LangId: "",
    address_FrontSide: "",
    address_BackSide: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      setFormData({ ...formData, [e.target.name]: reader.result });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const apiEndpoint =
      "https://dashboard.croesus-asset.management/api/physical-cards/holder-id-document";
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization:
        "Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg",
    };

    try {
      const response = await axios.post(apiEndpoint, formData, { headers });
      console.log(response.data); // Handle the response as needed
      onSubmitSuccess(true); // Call this when submission is successful
      setIsLoading(false);
    } catch (error) {
      console.error("There was an error!", error);
      setIsLoading(false);
    }
  };

  return (
    <section>
      <form onSubmit={handleSubmit}>
        <div className="row">
          {/* Proof of Address Fields */}
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label">Select Document*</label>
              <select
                name="address_TypeId"
                className="form-control"
                onChange={handleChange}
                value={formData.address_TypeId}
              >
                <option value="0">Credit Card Statement</option>
                <option value="1">Utility Bill</option>
                <option value="2">Bank Statement</option>
                <option value="3">Bank Letter</option>
              </select>
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label">Issued By *</label>
              <input
                type="text"
                name="address_IssuedBy"
                className="form-control"
                onChange={handleChange}
                value={formData.address_IssuedBy}
                required
              />
              Write the name of the institution or company that issued the proof
              of address document.
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label">Date of Issue*</label>
              <input
                type="date"
                name="address_IssuedDate"
                className="form-control"
                onChange={handleChange}
                value={formData.address_IssuedDate}
                required
              />
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label">Document Language*</label>
              <select
                name="address_LangId"
                className="form-control"
                onChange={handleChange}
                value={formData.address_LangId}
              >
                <option value="0">English</option>
                <option value="1">Spanish</option>
                <option value="2">French</option>
                <option value="3">Arabic</option>
                <option value="4">Chinese</option>
                <option value="5">Japanese</option>
                <option value="6">Russian</option>
                <option value="7">Portuguese</option>
                <option value="8">German</option>
                <option value="9">Italian</option>
                <option value="10">Hindi</option>
                <option value="11">Other</option>
              </select>
            </div>
          </div>
          <div className="col-12 mb-2">
            <div
              className="text-center"
              style={{ color: "red", fontSize: "1.125rem" }}
            >
              IMPORTANT: <br></br>- Do not blur out any details <br></br>- If
              the document has one page only, please upload the same file to
              Document Front and Document Back
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label">Document Front*</label>
              <input
                type="file"
                name="address_FrontSide"
                className="form-control"
                onChange={handleFileChange}
                required
              />
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label">Document Back*</label>
              <input
                type="file"
                name="address_BackSide"
                className="form-control"
                onChange={handleFileChange}
                required
              />
            </div>
          </div>{" "}
          <div className="col-lg-12">
            <button
              type="submit"
              className="btn btn-primary "
              style={{ display: "flex", alignItems: "center" }}
            >
              {isLoading ? (
                <>
                  <span
                    className="spinner-border text-secondary"
                    role="status"
                  ></span>
                  <div style={{ marginLeft: "10px" }}>Loading</div>
                </>
              ) : (
                <div>Submit</div>
              )}
            </button>
          </div>
        </div>
      </form>
    </section>
  );
};

export default StepThree;

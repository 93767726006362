import React, { useContext, useReducer, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import { Collapse, Dropdown } from "react-bootstrap";
import { MenuList } from "./Menu";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "",
  activeSubmenu: "",
};

const SideBar = () => {
  var d = new Date();
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
    ChangeIconSidebar,
  } = useContext(ThemeContext);

  const [state, setState] = useReducer(reducer, initialState);

  let handleheartBlast = document.querySelector(".heart");
  function heartBlast() {
    return handleheartBlast.classList.toggle("heart-blast");
  }

  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  const handleMenuActive = (status) => {
    setState({ active: status });
    if (state.active === status) {
      setState({ active: "" });
    }
  };
  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: status });
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" });
    }
  };
  // Menu dropdown list End

  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  return (
    <div className="deznav">
      <PerfectScrollbar className="deznav-scroll">
        <ul className="metismenu" id="menu">
          {MenuList.map((data, index) => {
            let menuClass = data.classsChange;
            if (menuClass === "menu-title") {
              return (
                <li className={menuClass} key={index}>
                  {data.title}
                </li>
              );
            } else {
              return (
                <li
                  className={` ${
                    state.active === data.title ? "mm-active" : ""
                  }`}
                  key={index}
                >
                  {data.content && data.content.length > 0 ? (
                    <>
                      <Link
                        to={"#"}
                        className="has-arrow"
                        onClick={() => {
                          handleMenuActive(data.title);
                        }}
                      >
                        {data.iconStyle}
                        <span className="nav-text">{data.title}</span>
                      </Link>
                      <Collapse in={state.active === data.title ? true : false}>
                        <ul
                          className={`${
                            menuClass === "mm-collapse" ? "mm-show" : ""
                          }`}
                        >
                          {data.content &&
                            data.content.map((submenuData, submenuIndex) => {
                              return (
                                <li
                                  key={`${index}-${submenuIndex}`} // generate unique key by combining index and submenuIndex
                                  className={`${
                                    state.activeSubmenu === submenuData.title
                                      ? "mm-active"
                                      : ""
                                  }`}
                                >
                                  {submenuData.content &&
                                  submenuData.content.length > 0 ? (
                                    <>
                                      <Link
                                        to={submenuData.to}
                                        className={
                                          submenuData.hasMenu ? "has-arrow" : ""
                                        }
                                        onClick={() => {
                                          handleSubmenuActive(
                                            submenuData.title
                                          );
                                        }}
                                      >
                                        {submenuData.title}
                                      </Link>
                                      <Collapse
                                        in={
                                          state.activeSubmenu ===
                                          submenuData.title
                                            ? true
                                            : false
                                        }
                                      >
                                        <ul
                                          className={`${
                                            menuClass === "mm-collapse"
                                              ? "mm-show"
                                              : ""
                                          }`}
                                        >
                                          {submenuData.content &&
                                            submenuData.content.map(
                                              (itemData, itemIndex) => {
                                                return (
                                                  <li
                                                    key={`${index}-${submenuIndex}-${itemIndex}`}
                                                  >
                                                    {" "}
                                                    {/* generate unique key */}
                                                    <Link
                                                      className={`${
                                                        path === itemData.to
                                                          ? "mm-active"
                                                          : ""
                                                      }`}
                                                      to={itemData.to}
                                                    >
                                                      {itemData.title}
                                                    </Link>
                                                  </li>
                                                );
                                              }
                                            )}
                                        </ul>
                                      </Collapse>
                                    </>
                                  ) : (
                                    <Link to={submenuData.to}>
                                      {submenuData.title}
                                    </Link>
                                  )}
                                </li>
                              );
                            })}
                        </ul>
                      </Collapse>
                    </>
                  ) : (
                    <Link to={data.to}>
                      {data.iconStyle}
                      <span className="nav-text">{data.title}</span>
                    </Link>
                  )}
                </li>
              );
            }
          })}
        </ul>
        <div className="copyright">
          <p>
            <strong>Bridge Bit</strong> © 2024 All Rights Reserved
          </p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
